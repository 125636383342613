import { useQuery, keepPreviousData } from '@tanstack/vue-query'
import type { IImportActivity } from '@workflow'

export const useApiImportDocumentsActive = (companyId: string) => {
  const { pagination, changePage } = usePagination({ persist: true })
  const search = ref()
  const query = useQuery({
    queryKey: [
      'getMeImportDocuments',
      companyId,
      'active',
      { pagination, search },
    ],
    queryFn: ({ signal }) =>
      fetchAuthorized<IImportActivity>(
        `/companies/${companyId}/me/importDocuments/active`,
        {
          query: {
            page: pagination.value.page,
            pageSize: pagination.value.pageSize,
            searchTerm: search.value,
          },
          signal,
        },
      ),
    placeholderData: keepPreviousData,
    refetchInterval: (query) => {
      // If data is processing, refresh after a period of time to update the progress
      if ((query.state.data?.active?.items?.length ?? 0) > 0) {
        return 1000 * 5
      }

      return false
    },
    staleTime: 0,
  })

  const activeTotalCount = computed(() => query.data.value?.activeCount ?? 0)
  const reviewTotalCount = computed(() => query.data.value?.succeededCount ?? 0)
  const failedTotalCount = computed(() => query.data.value?.failedCount ?? 0)
  const totalItemsCount = computed(() => query.data.value?.totalCount ?? 0)

  return {
    ...query,
    pagination,
    changePage,
    search,
    activeTotalCount,
    reviewTotalCount,
    failedTotalCount,
    totalItemsCount,
  }
}
